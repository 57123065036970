async function fetcher<T>(url: string, init?: RequestInit) {
  try {
    const response = await fetch(url, {
      ...init,
      headers: {
        ...init?.headers,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      return response.json() as Promise<T>;
    }

    return null;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export { fetcher };
